import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'
import { headerStore } from '@/stores/header'
import { errorNotification, successNotification } from '@/functions/notification'
import { subtotalStore } from '@/stores/subtotal'
import { Summary } from '@/types/summary/summary'

export const summaryStore = defineStore('summary', () => {
  const loading = ref(false)
  const phaseState = ref('loading')
  const summary = ref<Summary>({ modular: [], carcass: [], completion1: [], completion2: { levels: [] }, completion3: [], extra: [], excluded_from_warranty_options: false })
  const agree = ref({ warranty: false, responsibility: false, activities: false, terms: false })
  const creatingOrder = ref(false)
  const resettingCompletion = ref(false)

  function getSummary () : void {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/summary').then((r) => {
      summary.value = r.data
    }).finally(() => {
      loading.value = false
    })
  }

  function createOrder () : void {
    const agreedToAllTerms = summary.value.excluded_from_warranty_options ? (agree.value.warranty && agree.value.responsibility && agree.value.activities && agree.value.terms) : agree.value.terms
    if (agreedToAllTerms) {
      creatingOrder.value = true
      axios.get(process.env.VUE_APP_API_URL + '/completion/order').then(() => {
        agree.value = { warranty: false, responsibility: false, activities: false, terms: false }
        getPhaseState()
        headerStore().getOrdersReceived()
        successNotification('Bestelling geplaatst!', 'Uw bestelling voor de afbouw opties is succesvol geplaatst.')
      }).catch(() => {
        errorNotification('Bestelling NIET geplaatst!', 'Er is iets mis gegaan tijdens het plaatsen van uw bestelling. Probeer het nogmaals.')
      }).finally(() => {
        creatingOrder.value = false
      })
    }
  }

  function resetCompletion () : void {
    resettingCompletion.value = true
    axios.get(process.env.VUE_APP_API_URL + '/building/completion/reset').then(() => {
      getSummary()
      subtotalStore().getSubtotals()
      successNotification('Afbouw fase gereset.', 'De afbouw fase is succesvol gereset.')
    }).catch(() => {
      errorNotification('Afbouw fase NIET gereset!', 'Er is iets mis gegaan tijdens het resetten van de afbouw fase. Probeer het nogmaals.')
    }).finally(() => {
      resettingCompletion.value = false
    })
  }

  function getPhaseState () : void {
    axios.get(process.env.VUE_APP_API_URL + '/phase/2/closed').then((r) => {
      phaseState.value = r.data.status
    })
  }

  return { loading, phaseState, summary, agree, creatingOrder, resettingCompletion, getSummary, createOrder, resetCompletion, getPhaseState }
})
